<template>
    
    <b-card class="outer-card">
        <b-card class="inner-card">
            <div v-if="linkRemoved == 1">
                <div class="text-center" style="font-size: 1.5rem; font-weight: bold;">
                    Povezava za ponastavitev gesla je uspešno preklicana.
                </div>
                <div class="d-flex justify-content-center mt-1">
                    <b-button>Nazaj domov</b-button>
                </div>
            </div>
            <div v-else-if="linkRemoved == 2">
                <div class="text-center" style="font-size: 1.5rem; font-weight: bold;">
                    Pri preklicu ponastavitve gesla je prišlo do napake, prosim poskusite kasneje!
                </div>
            </div>
        </b-card>
    </b-card>

</template>

<script>
    import { BCard, BButton } from 'bootstrap-vue'

    export default {
        components:{
            BCard,
            BButton
        },
        data() {
            return {
                linkRemoved: null
            }
        },
        methods: {
            async cancelPasswordReset() {
                try {
                    await this.$http.get(`/api/user/v1/cancel_reset_password?linkId=${ this.$route.params.password_id}`)
                    this.linkRemoved = 1
                } catch (err) {
                    this.$printError('Prišlo je do napake prosim poskusite ponovno!')  
                    this.linkRemoved = 2
                }
            }
        },
        async mounted() {
            this.cancelPasswordReset()
        }
    }
</script>


<style scoped>
    .outer-card{
        box-shadow: none !important;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(180deg, rgba(234,243,249,1) 0%, rgba(234,243,249,1) 85%, rgba(255,255,255,1) 95%);
        min-height: 60vh !important;
    }

    .inner-card{
        box-shadow: none !important;
        margin-left: 2000px !important;
        margin-right: 2000px !important;
        border-radius: 10px !important;
    }

</style>